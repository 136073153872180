
<template>
    <div style="margin-top: -20px;max-width: 100%">
      <img
          style="width: 100%"
          src="https://alioss.techops.cn/static/indexBg.jpg"
          alt=""
      />
    </div>
</template>
<script>
export default {
    props: {},
    components: {},
    data() {
        return {

        }
    },
    async mounted() {
      // this.videoPlayer = await this.init();
    },
    methods: {

     /* videoClick() {
        if (this.videoPlayer.isPause) {
          this.videoPlayer.play();
        } else {
          this.videoPlayer.pause();
        }
        this.videoPlayer.isPause = !this.videoPlayer.isPause
      }*/
    },
};
</script>
<style>
</style>
